:root {
  font-size: 16px;
  line-height: 1.6;
}

body {
  font-family: dana, sans-serif;
  background-color: #1C1C1C;
  color: #fff;
}

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Add typography inheritance in all browsers (opinionated).
 */

button,
input,
select,
textarea {
  background-color: transparent; /* 1 */
  border: none; /* 1 */
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  letter-spacing: inherit; /* 2 */
  padding: 0.25em 0.375em; /* 1 */
}

/**
 * Restrict sizing to the page width in all browsers (opinionated).
 */

iframe,
input,
select,
textarea {
  height: auto;
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}
