#custom-player-wrapper {
  width: 100vw !important;
  height: 100% !important;
  position: fixed;
  top: 0;
  background-color: #000 !important;
  cursor: default;
}

.custom-player,
.custom-player video {
  width: 100vw !important;
  height: 100% !important;
  background-color: #000 !important;
}

.vjs-loading-spinner .vjs-control-text {
  display: none;
}

.vjs-loading-spinner {
  display: none;
  position: fixed;
  top: 49.6%;
  left: 49.8%;
  transform: translate(-50%, -50%);
  margin: 0 auto auto 0;
  opacity: 1;
  text-align: left;
  border: unset;
  border-style: solid;
  border-color: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border-width: 5px;
}

@media (max-width: 900px) and (min-width: 701px) {
  .vjs-loading-spinner {
    width: 3rem;
    height: 3rem;
    border-width: 4px;
  }
}
@media (max-width: 700px) {
  .vjs-loading-spinner {
    width: 1.5rem;
    height: 1.5rem;
    border-width: 3px;
    top: 49.6%;
    left: 49.5%;
  }
}

.vjs-loading-spinner:before {
  content: "";
  position: absolute;
  margin: unset;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  opacity: 1;
  border: inherit;
  border-color: #D32B4E;
  border-top-color: transparent;
}

.vjs-loading-spinner:after {
  display: none;
}

.vjs-waiting .vjs-loading-spinner:before {
  border-top-color: transparent;
}

.vjs-seeking .vjs-loading-spinner:before,
.vjs-waiting .vjs-loading-spinner:before {
  -webkit-animation: vjs-spinner-spin 0.5s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 0.5s linear infinite;
  animation: vjs-spinner-spin 0.5s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 0.5s linear infinite;
}

.vjs-seeking .vjs-loading-spinner,
.vjs-waiting .vjs-loading-spinner {
  display: block;
  -webkit-animation: vjs-spinner-show 0s linear 0.5s forwards;
  animation: vjs-spinner-show 0s linear 0.5s forwards;
}

@keyframes vjs-spinner-fade {
  0% {
    border-top-color: transparent;
  }

  20% {
    border-top-color: transparent;
  }

  35% {
    border-top-color: transparent;
  }

  60% {
    border-top-color: transparent;
  }

  100% {
    border-top-color: transparent;
  }
}

@-webkit-keyframes vjs-spinner-fade {
  0% {
    border-top-color: transparent;
  }

  20% {
    border-top-color: transparent;
  }

  35% {
    border-top-color: transparent;
  }

  60% {
    border-top-color: transparent;
  }

  100% {
    border-top-color: transparent;
  }
}

@media (max-width: 1280px) {
  #videojs-vast-skipButton {
    font-size: 0.9rem;
  }
}

@media (max-width: 960px) {
  #videojs-vast-skipButton {
    font-size: 0.85rem;
  }
}

@media (max-width: 600px) {
  #videojs-vast-skipButton {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  #videojs-vast-skipButton {
    font-size: 0.6rem;
  }
}

.vjs-poster {
  background-size: cover !important;
}

/*button:hover,*/
/*button:focus,*/
/*button:active {*/
/*  outline: none !important;*/
/*  box-shadow: 0 0 0 0 !important;*/
/*}*/

/*button:focus,*/
/*button:active {*/
/*  border-color: transparent !important;*/
/*}*/

.subPosition {
  bottom: 16% !important;
}

.vjs-text-track-display>div>div>div {
  font-family: dana !important;
  font-size: 2vw !important;
  background-color: hsla(0, 0%, 11%, 0.5) !important;
  color: #fff;
  padding: 0 0.5vw;
}

#custom-track {
  position: absolute;
  width: 100%;
  text-align: center;
}

#custom-track-inner span{
  font-family: dana !important;
  background-color: hsla(0, 0%, 11%, 0.5) !important;
  color: #FFF;
  font-size: 2vw !important;
  padding: 0 0.5vw;
  line-height: normal !important;
}

/*Handle native caption*/
.native-custom-player video::-webkit-media-text-track-container, .native-custom-player video::cue {
  /*direction: rtl !important;*/
  /*width: auto !important;*/
  /*justify-content: center !important;*/
  top: -12% !important;
  font-family: dana !important;
}

@media (min-device-width: 1000px) {
  .native-custom-player video::-webkit-media-text-track-container, .native-custom-player video::cue {
    font-size: 2vw !important;
    top: -4% !important;
    font-family: dana !important;
  }
}

.subRtlDirection video::-webkit-media-text-track-container, .native-custom-player video::cue {
  direction: rtl !important;
}

.subLtrDirection video::-webkit-media-text-track-container, .native-custom-player video::cue {
  direction: ltr !important;
}

.native-custom-simple-player video::-webkit-media-text-track-display {
  direction: rtl !important;
  width: auto !important;
  display: flex !important;
  justify-content: center !important;
}

.native-custom-simple-player video::-webkit-media-text-track-container {
  top: -12% !important;
}

.native-custom-simple-player video::-webkit-media-text-track-display {
  position: absolute !important;
  width: 50% !important;
  right: 25% !important;
  left: 25% !important;
}

.subRtlDirection::-webkit-media-text-track-container {
  direction: rtl !important;
}

.subLtrDirection::-webkit-media-text-track-container {
  direction: ltr !important;
}

.hide-native-caption video::-webkit-media-text-track-container {
  display: none !important;
}
